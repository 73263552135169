
const PATHS = {
    CHARGE_CATEGORY: 'charge-category',
    PAYMENT_METHOD: 'payment-method',
    TAX_CODE: 'tax-code',
    TAX_EXEMPT: 'tax-exempt',
    UNIT_TYPE: 'unit-type',
    PROMO_PLAN: 'promo-plan',
    PROMO_PLAN_TEMPLATE: 'promo-plan-template',
    LETTER: 'letter',
    LATE_EVENT: 'late-event',
    MAINTENANCE_EVENT: 'maintenance-event',
    OPERATIONS_MAINTENANCE_EVENTS: 'maintenance-events',
    LEASE_STYLE: 'lease-style',
    RENT_ADJUSTMENT: 'rent-adjustment',
    INSURANCE: 'insurance',
    CARD_PROCESSOR: 'card-processor',
    OPERATIONS_BATCH_PAYMENT: 'batch-payment',
    VIOLATION: 'violation',
};

const ROUTE = {
    PENDING_TASKS: 'pending-tasks',
    LIVE_DATA: 'l/:accountId?/facility/:fid?',
    PULLED_DATA: 'p/:sandboxId?/:accountId?/facility/:fid?',
    EXTERNAL_MAIN: 'a/facility/:fid?',
    SETTINGS_LIVE: 'l/s/:accountId?',
    SETTINGS_PULLED: 'p/s/:sandboxId?/:accountId?',
    SETTINGS_MAIN: 'a/s',
    ONBOARDING: 'onboarding',
    TENANT_LIST: 'tenant/list',
    FINANCIAL_LIST: 'financial/list',
    UNIT_LIST: 'unit/list',
    LEAD_LIST: 'lead/list',
    RESERVATION_LIST: 'reservation/list',
    EDIT_RESERVATION: 'reservation/:reservationId/edit',
    EDIT_WAITING: 'waiting/:waitingId/edit',
    VENDOR_LIST: 'vendor/list',
    INVENTORY_LIST: 'inventory/list',
    EDIT_TENANT: 'tenant/:tenantId/edit/:sectionLabel?/:subSectionId?',
    VIEW_TENANT: 'tenant/:tenantId/view',
    CREATE_TENANT: 'tenant/create/:sectionLabel?',
    LEDGER_REVIEW: 'ledger/:ledgerId',
    CREATE_LEAD: 'lead/create',
    EDIT_LEAD: 'lead/:leadId/edit',
    CREATE_AR: 'financial/ar/create',
    EDIT_UNIT: 'unit/:unitId/edit',
    CREATE_UNIT: 'unit/create',
    MOVE_IN: 'movein',
    MOVE_OUT: 'moveout',
    USER_PROFILE: 'user-profile/:userId',
    PAYMENT: 'payment',
    USER_LIST: 'user/list',
    CREATE_USER: 'user/create',
    EDIT_USER: 'user/:userId/edit',
    CONFIGURATION: 'c/:entityLevel/:entityId?',
    SYSTEM_CONFIGURATION: 'sc/:entityLevel?',
    CHARGE_CATEGORY_LIST: `${PATHS.CHARGE_CATEGORY}/list`,
    PAYMENT_METHOD_LIST: `${PATHS.PAYMENT_METHOD}/list`,
    TAX_CODE_LIST: `${PATHS.TAX_CODE}/list`,
    TAX_EXEMPT_LIST: `${PATHS.TAX_EXEMPT}/list`,
    UNIT_TYPE_LIST: `${PATHS.UNIT_TYPE}/list`,
    FINANCIAL_GENERAL: 'financial-general',
    FACILITY_GENERAL: 'facility-general',
    FINANCIAL_EXPORT: 'financial-export',
    VIOLATION_LIST: `${PATHS.VIOLATION}/list`,
    VIOLATION_CONFIGURATION: `${PATHS.VIOLATION}/:configId?`,
    GATES: 'gates',
    RETAIL_SALE: 'retail-sale',
    ACH: 'ach',
    QUICKBOOK_CONFIG: 'quickbook',
    BEV_COLOR_SETUP: 'bev-color-setup',
    WALK_AROUND: 'walk-around',
    MAINTENANCE_EVENT_LIST: `${PATHS.MAINTENANCE_EVENT}/list`,
    MAINTENANCE_EVENT_FORM: `${PATHS.MAINTENANCE_EVENT}/:configId?`,
    CUSTOMER_GENERAL: 'customer-general',
    RENTAL_GENERAL: 'rental-general',
    INSURANCE: 'insurance',
    PROMO_PLAN_LIST: `${PATHS.PROMO_PLAN}/list`,
    PROMO_PLAN_TEMPLATE_LIST: `${PATHS.PROMO_PLAN_TEMPLATE}/list`,
    PROMO_PLAN_FORM: `${PATHS.PROMO_PLAN}/:configId?`,
    PROMO_PLAN_TEMPLATE_FORM: `${PATHS.PROMO_PLAN_TEMPLATE}/:configId?`,
    UNIT_TYPE_FORM: `${PATHS.UNIT_TYPE}/:configId?`,
    RESERVATION: 'reservation',
    ESTIMATE: 'estimate',
    WAITING: 'waiting',
    CHARGE_CATEGORY: `${PATHS.CHARGE_CATEGORY}/:configId?`,
    PAYMENT_METHOD: `${PATHS.PAYMENT_METHOD}/:configId?`,
    TAX_CODE: `${PATHS.TAX_CODE}/:configId?`,
    TAX_EXEMPT_CATEGORY: `${PATHS.TAX_EXEMPT}/:configId?`,
    PAYMENT_CONFIGURATION: 'payment-configuration',
    CHARGE_CATEGORY_MAPPING: 'charge-category-mapping',
    TENANT_CHANGE_TRACKING: 'tenant-change-tracking',
    TENANT_ACCOUNT_TYPE: 'tenant-account-type',
    TENANT_SECTIONS: 'tenant-sections',
    USER_ROLE: 'user-role',
    CARD_PROCESSOR_LIST: `${PATHS.CARD_PROCESSOR}/list`,
    CARD_PROCESSOR: `${PATHS.CARD_PROCESSOR}/:configId?`,
    EDIT_USER_ROLE: 'user-role/:roleId/edit',
    CREATE_ROLE: 'user-role/create',
    BEV_VIEW: 'bev/view',
    BEV_SETUP: 'bev/setup',
    POINT_OF_SALE: 'pos',
    RETAIL_SALE_RETURNS: 'returns',
    LETTER_LIST: `${PATHS.LETTER}/list`,
    LETTER_CONFIGURATION: `${PATHS.LETTER}/:configId?`,
    REPORT_WC: 'report/:entityLevel?/:entityId?',
    CONSOLIDATED_REPORT_WC: 'consolidated-report/:entityLevel?/:entityId?',
    LATE_EVENT_LIST: `${PATHS.LATE_EVENT}/list`,
    LATE_EVENT_FORM: `${PATHS.LATE_EVENT}/:configId?`,
    CONVERSATIONS: 'conversations',
    EMAIL_AUDIT: 'conversations/email-audit/list',
    INTERNAL_APP: 'internal-app',
    DOCS_RB: 'reprint',
    DOCS_GD: 'generate-documents',
    WAITING_LIST: 'waiting/list',
    REPORT: 'report',
    CONSOLIDATED_REPORT: 'consolidated-report',

    // Operations Route
    OPERATIONS: 'operations',
    MANUAL_LATE_EVENT: 'manual-late-event',
    MANUAL_RENT_ASSESSMENT: 'manual-rent-assessment',
    ACH_OPERATIONS: 'ach-operations',
    BATCH_PAYMENT: 'batch-payment',
    LETTER_UTILITY: 'letter-utility', // Change to Admin Utility when speced out
    OPERATIONS_MAINTENANCE_EVENTS_LIST: `${PATHS.OPERATIONS_MAINTENANCE_EVENTS}/list`,
    OPERATIONS_MAINTENANCE_EVENTS_FORM: `${PATHS.OPERATIONS_MAINTENANCE_EVENTS}/create`,
    OPERATIONS_MAINTENANCE_EVENTS_EDIT: `${PATHS.OPERATIONS_MAINTENANCE_EVENTS}/:eventId/edit`,
    RENT_ADJUSTMENT_LIST: `${PATHS.RENT_ADJUSTMENT}/list`,
    MANUAL_RENT_ADJUSTMENT_CREATE: `${PATHS.RENT_ADJUSTMENT}/manual/create`,
    AUTO_RENT_ADJUSTMENT_CREATE: `${PATHS.RENT_ADJUSTMENT}/auto/create`,
    VIEW_RENT_ADJUSTMENT: `${PATHS.RENT_ADJUSTMENT}/:rentAdjustmentId/view`,
    DEPOSIT_LOG: 'deposit-log',
    OPERATIONS_CREDIT_CARD_BATCH_CREATE: `${PATHS.OPERATIONS_BATCH_PAYMENT}/credit-card/create`,
    OPERATIONS_CASH_CHECK_MONEY_ORDER_BATCH_CREATE: `${PATHS.OPERATIONS_BATCH_PAYMENT}/other-batch/create`,
    OPERATIONS_ACH_CREATE: `${PATHS.OPERATIONS_BATCH_PAYMENT}/ach/create`,
    TENANT_WEBSITES: 'tenant-websites',
    DELINQUENCY_LIST: 'delinquency/list',
    DEPOSIT_UPDATE: 'deposit-update',

    LEASE_GENERAL: 'lease-general',
    LEASE_STYLE_LIST: `${PATHS.LEASE_STYLE}/list`,
    LEASE_STYLE_FORM: `${PATHS.LEASE_STYLE}/:configId?`,

    PENDING_OVERLOCK_UNLOCK_LIST: 'pending-overlock-unlock/list',
    INVOICING: 'invoicing',
    TRANSFER: 'transfer',

    AUTOMATIC_CREDIT_CARD_DECLINES_LIST: 'automatic-credit-card-declines/list',
    FAILED_RETURNS_LIST: 'failed-returns/list',

    TENANT_CHANGES: 'tenant-changes/list',
    OTHER_EXTERNAL_ACTIVITY: activityType => `other-external-activity/${activityType}/list`,
    VEHICLE: 'vehicle/list',

    // batch payment
    VIEW_CC_BATCH: 'cc-batch/:batchId/view',
    VIEW_ACH_BATCH: 'ach-batch/:batchId/view',

    UNIT_TYPE_BULK: 'unit-type/bulk',

    SINGLE_UNIT_RENT_ADJUSTMENT: 'unit/:unitId/rent-adjustment',
    PRINT_FAILURES: 'print-failures/list',
    DELIVER_INVOICE: 'deliver-invoice/list',
    CC_ABOUT_TO_EXPIRE: 'cc-expiry/list',
};


export { PATHS, ROUTE };
