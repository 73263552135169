import estimatePostProcessor from 'External/redux/postProcessors/estimatePostProcessor';
import unitPostProcessor from './unitPostProcessor';
import { configPostProcessor } from './configPostProcessor';
import configSubPostProcessor from './configSubPostProcessor';
import reservationPostProcessor from './reservationPostProcessor';
import inventoryListPostProcessor from './inventoryListPostProcessor';
import unpaidArPostProcessor from './unpaidArPostProcessor';
import contactLogPostProcessor from './contactLogPostProcessor';
import arPostProcessor from './arPostProcessor';
import facilityPostProcessor from './facilityPostProcessor';
import { manualLateEventPostProcessor } from './manualLateEventPostProcessor';
import maintenanceOperationPostProcessor from './maintenanceOperationPostProcessor';
import unitBevMiniPostProcessor from './unitBevMiniPostProcessor';
import leadPostProcessor from './leadPostProcessor';
import { delinquencyPostProcessor } from './delinquencyPostProcessor';
import { configSDPostProcessor } from './configSDPostProcessor';
import preRequisitePostProcessor from './preRequisitePostProcessor';
import reportsPostProcessor from './reportsPostProcessor';
import unitBevPostProcessor from './unitBevPostProcessor';
import timezonePostProcessor from './timezonePostProcessor';
import textConversationPostProcessor from './textConversationPostProcessor';
import emailConversationPostProcessor from './emailConversationPostProcessor';
import ledgerAccessCodePostProcessor from './ledgerAccessCodePostProcessor';
import unitAccessCodePostProcessor from './unitAccessCodePostProcessor';
import achTransactionPostProcessor from './achTransactionPostProcessor';
import textPostProcessor from './textPostProcessor';
import emailPostProcessor from './emailPostProcessor';
import rentAdjustmentPostProcessor from './rentAdjustmentPostProcessor';
import rateHistoryPostProcessor from './rateHistoryPostProcessor';
import tenantsForDocumentPostProcessor from './tenantsForDocumentPostProcessor';
import reprintDataPostProcessor from './reprintDataPostProcessor';
import viewRentAdjustmentGridPostProcessor from './viewRentAdjustmentGridPostProcessor';
import automaticCreditCardDeclinesPostProcessor from './automaticCreditCardDeclinesPostProcessor';
import failedReturnsPostProcessor from './failedReturnsPostProcessor';
import lateEventsPostProcessor from './lateEventsPostProcessor';
import tenantEmailChangesPostProcessor from './tenantEmailChangesPostProcessor';
import waitingListPostProcessor from './waitingListPostProcessor';
import moveInSearchPostProcessor from './moveInSearchPostProcessor';
import paymentPostProcessor from './paymentPostProcessor';
import vehicleDataPostProcessor from './vehicleDataPostProcessor';
import sampleLetterPostProcessor from './sampleLetterPostProcessor';
import printFailuresPostProcessor from './printFailuresPostProcessor';
import ccAboutToExpirePostProcessor from './ccAboutToExpirePostProcessor';
import pendingInvoiceDeliveryPostProcessor from './pendingInvoiceDeliveryPostProcessor';
import tenantListPostProcessor from './tenantListPostProcessor';
import emailAuditPostProcessor from './emailAuditPostProcessor';
import overbookUnitsCountPostProcessor from './overbookUnitsCountPostProcessor';
import savedDocumentPostProcessor from './savedDocumentPostProcessor';
import unitsWithInvalidAccessCodesPostProcessor from './unitsWithInvalidAccessCodesPostProcessor';
import tenantsWithInvalidAccessCodesPostProcessor from './tenantsWithInvalidAccessCodesPostProcessor';
import { otherMethodBatchPaymentTenantsPostProcessor } from './otherMethodBatchPaymentTenantsPostProcessor';

const tenantPhoneChangesPostProcessor = tenantEmailChangesPostProcessor;
const tenantAlternateContactChangesPostProcessor = tenantEmailChangesPostProcessor;
const tenantAddressChangesPostProcessor = tenantEmailChangesPostProcessor;

export {
    reportsPostProcessor,
    configPostProcessor,
    unitPostProcessor,
    configSubPostProcessor,
    reservationPostProcessor,
    inventoryListPostProcessor,
    unpaidArPostProcessor,
    contactLogPostProcessor,
    arPostProcessor,
    facilityPostProcessor,
    manualLateEventPostProcessor,
    maintenanceOperationPostProcessor,
    unitBevMiniPostProcessor,
    leadPostProcessor,
    estimatePostProcessor,
    delinquencyPostProcessor,
    configSDPostProcessor,
    preRequisitePostProcessor,
    unitBevPostProcessor,
    timezonePostProcessor,
    textConversationPostProcessor,
    emailConversationPostProcessor,
    ledgerAccessCodePostProcessor,
    unitAccessCodePostProcessor,
    achTransactionPostProcessor,
    textPostProcessor,
    emailPostProcessor,
    rentAdjustmentPostProcessor,
    rateHistoryPostProcessor,
    tenantsForDocumentPostProcessor,
    reprintDataPostProcessor,
    viewRentAdjustmentGridPostProcessor,
    automaticCreditCardDeclinesPostProcessor,
    failedReturnsPostProcessor,
    lateEventsPostProcessor,
    tenantEmailChangesPostProcessor,
    tenantAddressChangesPostProcessor,
    tenantPhoneChangesPostProcessor,
    tenantAlternateContactChangesPostProcessor,
    waitingListPostProcessor,
    moveInSearchPostProcessor,
    paymentPostProcessor,
    vehicleDataPostProcessor,
    sampleLetterPostProcessor,
    printFailuresPostProcessor,
    ccAboutToExpirePostProcessor,
    pendingInvoiceDeliveryPostProcessor,
    tenantListPostProcessor,
    emailAuditPostProcessor,
    overbookUnitsCountPostProcessor,
    savedDocumentPostProcessor,
    unitsWithInvalidAccessCodesPostProcessor,
    tenantsWithInvalidAccessCodesPostProcessor,
    otherMethodBatchPaymentTenantsPostProcessor,
};
