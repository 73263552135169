import STATUS from 'Commons/config/constants/StoreKeyStatus';
import baseProcessor from 'Commons/redux/postProcessor/BaseProcessor';

const otherMethodBatchPaymentTenantsPostProcessor = (apiResult, callback, dispatch, localReqOptions) => {
    const processedData = baseProcessor(apiResult, callback, dispatch, localReqOptions);

    const { data: allProcessedData, data: { data: tenants = [], totalCount: resTotalCount = 0 } = {} } = processedData || {};
    const data = [];

    const { storeData: { otherMethodBatchPaymentTenants: { data: { actualRows = 0, totalCount = 0 } = {} } = {} } = {} } = localReqOptions || {};
    let newTotalCount = totalCount || resTotalCount;

    if (tenants && Array.isArray(tenants) && tenants.length) {
        tenants.forEach((tenant) => {
            const { ledger = [], name } = tenant;
            ledger.forEach((eachLedger) => {
                const { id, balance, label } = eachLedger;
                data.push({ tenantName: name, ledgerId: id, label, balance, ...eachLedger });
            });
            newTotalCount += (ledger.length - 1);
        });
    }
    return {
        data: {
            ...allProcessedData,
            data,
            totalCount: newTotalCount,
            actualRows: actualRows + tenants.length,
        },
        status: STATUS.LOADED,
    };
};

export { otherMethodBatchPaymentTenantsPostProcessor };
