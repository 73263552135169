import { STATUS } from 'Commons/config/constants/Constants';
import { isSyrasoftUser } from 'Commons/helpers/utils/Utils';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';

const getCurrentFacility = (store, routeProps) => {
    // TODO: change key defaultFacilityId(assumed key) accordingly
    const {
        userProfile: { data: { data: [{ defaultFacilityId } = {}] = [], data: userProfile = [] } },
        facility: { data: { data: facilityList = [] } },
    } = store;

    const { match: { params: { fid } } } = routeProps;

    if (fid) return facilityList.find(({ id }) => id === fid);

    return (userProfile.length && defaultFacilityId)
        ? (defaultFacilityId && this.facilityMap[defaultFacilityId])
        : facilityList[0] && facilityList[0];
};

const resolveConfiguration = (store, configType, extraFilters = {}) => {
    const { currentFacility: { data: { id: entityId } = {} } = {} } = store;
    return {
        api: {
            body: {
                filter: [{
                    terms: {
                        configType: [configType],
                        entityType: ['Facility'],
                        ...(entityId && { entityId: [entityId] }),
                        ...extraFilters,
                    },
                }],
            },
        },
    };
};

const onMountConfigs = {
    resolveRequiredFields: (store = {}) => resolveConfiguration(store, 'Required_Fields'),
    resolveUnitType: (store = {}) => resolveConfiguration(store, 'Unit_Type'),
    resolveSuggestedFields: (store = {}) => resolveConfiguration(store, 'Suggested_Fields'),
    resolveCustomerGeneral: (store = {}) => resolveConfiguration(store, 'Customer_General'),
    resolveRentalGeneral: (store = {}) => resolveConfiguration(store, 'Rental_General'),
    resolveMoveIn: (store = {}) => resolveConfiguration(store, 'Move_In'),
    resolveChargeCategory: (store = {}) => resolveConfiguration(store, 'Charge_Category'),
    resolveTaxExemptCategory: (store = {}) => resolveConfiguration(store, 'Tax_Exempt_Category'),
    resolveMoveOut: (store = {}) => resolveConfiguration(store, 'Move_Out'),
    resolvePaymentMethod: (store = {}) => resolveConfiguration(store, 'Payment_Method'),
    resolveTenantWebsites: (store = {}) => resolveConfiguration(store, 'Tenant_Websites'),
    resolveFinancialPayment: (store = {}) => resolveConfiguration(store, 'Financial_Payment'),
    resolveTenantSections: (store = {}) => resolveConfiguration(store, 'Tenant_Sections'),
    resolveLevel: (store = {}) => resolveConfiguration(store, 'Level'),
    resolveInventory: (store = {}) => resolveConfiguration(store, 'Inventory'),
    resolveLetter: (store = {}) => resolveConfiguration(store, 'Letter'),
    resolveBevColors: (store = {}) => resolveConfiguration(store, 'Bev_Colors'),
    resolveLateEvent: (store = {}) => resolveConfiguration(store, 'Late_Event'),
    resolveActiveLateEvents: (store = {}) => resolveConfiguration(store, 'Late_Event', { value: { status: ['Active'] } }),
    resolveFacilityGeneral: (store = {}) => resolveConfiguration(store, 'Facility_General'),
    resolveCardProcessor: (store = {}) => resolveConfiguration(store, 'Card_Processor'),
    resolveLeaseStyle: (store = {}) => resolveConfiguration(store, 'Lease_Style'),
    resolveMaintenanceEvent: (store = {}) => resolveConfiguration(store, 'Maintenance_Event'),
    resolveInvoicing: (store = {}) => resolveConfiguration(store, 'Invoicing'),
    resolveGate: (store = {}) => resolveConfiguration(store, 'Gate'),
    resolveTransfer: (store = {}) => resolveConfiguration(store, 'Transfer'),
    resolveInsuranceCoverage: (store = {}) => resolveConfiguration(store, 'Insurance_Coverage'),
    resolvePromoPlan: (store = {}) => resolveConfiguration(store, 'Promo_Plan'),
    resolveVehicle: (store = {}) => resolveConfiguration(store, CONFIG_TYPE.VEHICLE),
    resolvePlaceholder: (store = {}) => resolveConfiguration(store, CONFIG_TYPE.PLACEHOLDER),
    resolveFinancialGeneral: (store = {}) => resolveConfiguration(store, CONFIG_TYPE.FINANCIAL_GENERAL),
    resolveViolation: (store = {}) => resolveConfiguration(store, 'Violation'),

    resolveCurrentAccountId: (_, __, routeProps = {}) => {
        const { match: { params: { accountId } } = {} } = routeProps;
        return {
            store: {
                key: 'currentAccountId',
                actionKey: 'currentAccountId_create',
                payload: { data: { id: accountId } },
            },
            method: 'create',
        };
    },

    resolveFacility: (_, defaultConfig = {}, routeProps = {}) => {
        const { match: { params: { accountId } } = {} } = routeProps;
        return {
            api: {
                ...defaultConfig,
                ...accountId && {
                    customHeaderOptions: {
                        type: 'default',
                        context: { id: accountId, type: 'Account' },
                    },
                },
            },
            store: {
                key: 'facility',
                actionKey: 'facility_read',
            },
        };
    },

    resolveCurrentFacility: (store = {}, _, routeProps = {}) => {
        const payload = getCurrentFacility(store, routeProps);
        return {
            store: {
                key: 'currentFacility',
                actionKey: 'currentFacility_create',
                payload: { data: payload },
            },
            method: 'create',
        };
    },

    resolvePermission: (store = {}) => {
        const {
            currentFacility: { data: { permissionRoleId } = {} } = {},
            permissionRoles: { data: { data: roles = {} } = {} } = {},
            userProfile: { data: { data: [{ userType } = {}] = [] } = {} } = {},
        } = store;
        return {
            store: {
                key: 'permission',
                actionKey: 'permission_create',
                payload: {
                    data: {
                        data: (isSyrasoftUser(userType)
                            ? roles['-1'] : roles[permissionRoleId]) || {},
                    },
                },
            },
            method: 'create',
        };
    },

    resolveChargeCategoryWithRestricted: (store = {}) => {
        const config = resolveConfiguration(store, 'Charge_Category');
        // eslint-disable-next-line no-underscore-dangle
        config.api.body.__includeRestricted = ['Prepay'];
        return config;
    },

    resolveActivePaymentMethod: (store = {}) => {
        const extraFilters = { value: { status: [STATUS.Active.value] } };
        return resolveConfiguration(store, 'Payment_Method', extraFilters);
    },

    resolvePaymentAccessiblePromoPlans: (store = {}) => {
        const extraFilters = { value: { accessibility: { features: { Payment: [true] } } } };
        return resolveConfiguration(store, 'Promo_Plan', extraFilters);
    },

    resolveActiveCardProcessor: (store = {}) => {
        const extraFilters = { value: { status: [STATUS.Active.value] } };
        return resolveConfiguration(store, 'Card_Processor', extraFilters);
    },

    resolvePendingRentAdjustmentsForAUnit: (_, defaultConfig, routeProps = {}, storeConfig) => {
        const { match: { params: { unitId } = {} } = {} } = routeProps;
        const { body: { filter: [{ terms: defaultTerms } = {}] = [] } = {}, ...rest } = defaultConfig;
        return {
            api: {
                ...rest,
                body: {
                    view: 'mini',
                    filter: [{
                        terms: {
                            ...defaultTerms,
                            unitId: [unitId],
                        },
                    }],
                },
            },
            store: storeConfig,
        };
    },
};

export default onMountConfigs;
